import React, { useState } from "react"
import GitexPopupModal from "../../core/GitexPopupModal"
import H2 from "../../core/H2"
// import H2 from "../../core/H2"
import H3 from "../../core/H3"
import P from "../../core/P"
export const SplitWithImage = ({
    title,
    subtitle,
    image,
    action1,
    action2,
}) => {
    //remove after gitex2022 start
    const [openGitex, setOpenGitex] = useState(false)
    const openGitexModal = () => {
        setOpenGitex(true)
    }
    const closeGitexModal = () => {
        setOpenGitex(false)
    }
    //remove after gitex2022 end
    return (
        <>
            {/* remove after gitex2022 start */}
            <GitexPopupModal open={openGitex} closeGitexModal={closeGitexModal} url={"https://calendly.com/webmob"}/>  
            <div className="bg-blue-500 lg:py-12 md:py-10 py-6 grid md:grid-cols-2 grid-cols-1 gap-4">
                <div className="md:flex items-center block overflow-hidden ">
                    <div className="px-4 sm:px-6 lg:px-8 md:my-auto block my-5 md:flex flex-wrap content-center items-center justify-center" data-aos="fade-right" >
                        <img
                            className="lg:h-96 2xl:h-auto lg:w-auto 2xl:w-11/12 md:w-full md:h-auto w-full h-auto object-cover"
                            src={image?.mediaItemUrl}
                            alt={image?.altText ? image.altText : "Support team"}
                            width={image?.mediaDetails?.width}
                            height={image?.mediaDetails?.height}
                            title="Support team"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="md:flex items-center block overflow-hidden ">
                    <div className="px-4 md:pl-0 lg:pl-0 lg:my-auto md:my-5 my-5 bg-transparent lg:w-11/12 md:w-full w-full" data-aos="fade-left">
                        {
                            subtitle ?
                                <>
                                    <H3 title={title} className="text-white text-center md:text-left" />
                                    <P title={subtitle}  className={`text-white text-center md:text-left ` } />
                                </> :
                                <>
                                    {title && <H2 title={title} theme="white" className="md:text-left" />}
                                </>
                        }
                        <div className="mt-8 flex justify-center md:justify-start">
                            {action1 && action1.url.charAt(0) === "#" && action1?.url?.length===1 ?
                                <button
                                    className="inline-flex relative px-5 py-3 text-base leading-6 font-medium text-shark-500 bg-yellow-500 hover:bg-yellow-600 hover:text-shark-600 rounded border-yellow-500 border-2 cursor-pointer"
                                    onClick={openGitexModal} //remove after gitex2022
                                >
                                    {action1?.title}
                                </button>
                                :
                                <a
                                    href={action1?.url}
                                    className="inline-flex relative px-5 py-3 text-base leading-6 font-medium text-shark-500 bg-yellow-500 hover:bg-yellow-600 hover:text-shark-600 rounded border-yellow-500 border-2"
                                >
                                    {action1?.title}
                                </a>
                            }
                            {action2 && (
                                <div className="ml-3 inline-flex">
                                    <a
                                        href={action2?.url}
                                        className="inline-flex relative px-5 py-3 text-base leading-6 font-medium text-blue-500 bg-white hover:text-blue-600 rounded border-white border-2"
                                    >
                                        {action2?.title}
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SplitWithImage
